import Link from 'next/link';
import textStyle from 'components/BlcText/style.js';
import textProp, { BlcTextDefaultProps } from 'components/BlcText/props.js';
import prop, { BlcLinkDefaultProps } from 'components/BlcText/Link/props.js';
import { colors } from '@Backlot-Cars/archie';
import { fontWeight, textSize, textSizeML, textSizeMS, textSizeMXS, textLineHeight } from 'helpers/text';
import { margin, marginML, marginMS, marginMXS } from 'helpers/margin';
import { media } from 'public/styles';

const BlcLink = (props) => {
	return (
		<Link as={props.as} href={props.href} passHref prefetch={props.prefetch} legacyBehavior>
			<a
				className={props.active ? 'active' : null}
				target={props.target}
				href={props.href}
				aria-label={`Link to ${props.href}`}
			>
				{props.children}

				<style jsx>{textStyle}</style>
				<style jsx>{`
					appearance: none;
					${props.width ? `width: ${props.width};` : ''}
					color: ${colors[props.color]};
					display: ${props.display};
					font-size: ${textSize(props.size)};
					font-weight: ${fontWeight(props.fontWeight)};
					line-height: ${textLineHeight(props.size)};
					margin: ${margin(props.margin)} 0;
					text-align: ${props.textAlign};
					text-decoration: ${props.underline ? 'underline' : 'none'};
					transition: color 0.15s;
					:active,
					:focus {
						color: ${colors[props.colorFocus]};
					}
					:hover {
						color: ${colors[props.colorHover]} !important;
					}
					:visited {
						color: ${colors[props.color]};
					}
					.active {
						color: ${colors[props.colorActive]};
					}
					@media ${media.larger} {
						a {
							font-size: ${textSizeML(props.size)};
							margin: ${marginML(props.margin)} 0;
						}
					}
					@media ${media.smaller} {
						a {
							font-size: ${textSizeMS(props.size)};
							margin: ${marginMS(props.margin)} 0;
						}
					}
					@media ${media.smallest} {
						a {
							font-size: ${textSizeMXS(props.size)};
							margin: ${marginMXS(props.margin)} 0;
						}
					}
				`}</style>
			</a>
		</Link>
	);
};

BlcLink.propTypes = {
	...textProp,
	...prop,
};

BlcLink.defaultProps = {
	...BlcTextDefaultProps,
	...BlcLinkDefaultProps,
};

export default BlcLink;
