import prop from 'components/BlcText/Link/props.js';
import textProp from 'components/BlcText/props.js';
import BlcLink from '.';

// themed Link to match brand style
const BrandLink = (props) => {
	const { href, children } = props;
	return (
		<BlcLink
			href={href}
			display="unset"
			margin="none"
			size="normal"
			fontWeight="bold"
			color="primary"
			{...props}
		>
			{children}
		</BlcLink>
	);
};

BrandLink.propTypes = {
	...textProp,
	...prop,
};


export default BrandLink;
