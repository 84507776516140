import PropTypes from 'prop-types';

const BlcTextType = {
	/**
	 * Set the Text children
	 */
	children: PropTypes.node,
	/**
	 * set the Text color
	 */
	color: PropTypes.string,
	/**
	 * set the Text display (type of element)
	 */
	display: PropTypes.oneOf([
		'inline',
		'inline-block',
		'block',
		'flex',
		'list-item',
		'unset',
	]),
	/**
	 * Set the Text fontWeight
	 */
	fontWeight: PropTypes.oneOf([
		'inherit',
		'regular',
		'bold'
	]),
	/**
	 * Set the Text margin
	 */
	margin: PropTypes.oneOf([
		'largest',
		'larger',
		'normal',
		'smaller',
		'smallest',
		'none'
	]),
	/**
	 * Set a Text as one line copy
	 */
	nowrap: PropTypes.bool,
	/**
	 * Choose between this Text sizes
	 */
	size: PropTypes.oneOf([
		'largest',
		'larger',
		'normal',
		'smaller',
		'smallest',
		'inherit'
	]),
	/**
	 * Set the Text's text-type content alignment
	 */
	textAlign: PropTypes.oneOf([
		'inherit',
		'left',
		'center',
		'right'
	])
};

export const BlcTextDefaultProps = {
	children: null,
	color: 'asphalt',
	display: 'block',
	fontWeight: 'regular',
	margin: 'smallest',
	nowrap: false,
	size: 'normal',
	textAlign: 'inherit'
};

export const BlcTextTypeProps = () => (<></>);

BlcTextTypeProps.defaultProps = BlcTextDefaultProps;

BlcTextTypeProps.propTypes = BlcTextType;

export default BlcTextType;
