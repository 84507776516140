import PropTypes from 'prop-types';

const BlcLinkType = {
	/**
	 * Set the link as active
	 */
	active: PropTypes.bool,
	/**
	 * Set the link active color
	 */
	colorActive: PropTypes.string,
	/**
	 * Set the link focus color
	 */
	colorFocus: PropTypes.string,
	/**
	 * Set the link hover color
	 */
	colorHover: PropTypes.string,
	/**
	 * Set the link anchor
	 */
	href: PropTypes.string,
	/**
	 * Disables external links
	 */
	prefetch: PropTypes.bool,
	/**
	 * Set the link anchor target
	 */
	target: PropTypes.oneOf([
		'_blank',
		'_self',
		'_parent',
		'_top'
	]),
	/**
	 * Set the link decoration
	 */
	underline: PropTypes.bool,
	/**
	 * Set the link as
	 */
	as: PropTypes.string,
	/**
	 * Set the on click query
	 */
	query: PropTypes.object,
	/**
	 * Set if the link is an external url
	 */
	external: PropTypes.bool
};

export const BlcLinkDefaultProps = {
	active: false,
	color: 'grayDark',
	colorActive: 'primary',
	colorFocus: 'secondary',
	colorHover: 'grayLight',
	display: 'inline-block',
	href: '#',
	target: null,
	underline: true,
	query: null,
	external: null
};

export const BlcLinkTypeProps = () => (<></>);

BlcLinkTypeProps.defaultProps = BlcLinkDefaultProps;

BlcLinkTypeProps.propTypes = BlcLinkType;

export default BlcLinkType;
