import styled from 'styled-components';
import { media } from 'public/styles';
import { colors, shadow } from '@Backlot-Cars/archie';

export const TwoColumn = styled.div`
	position: relative;
	z-index: 3;
	display: flex;
	flex-direction: row;
	padding: 80px 40px;
	overflow: hidden;
	justify-content: space-around;
	gap: 60px;
	align-items: center;

	@media ${media.smaller} {
		flex-direction: column;
		padding: 24px 16px;
		gap: 32px;
		align-items: unset;
	}
`;

export const FirstColumn = styled.div`
	display: flex;
	flex: 1;
	max-width: 40%;
	flex-direction: column;
	align-items: baseline;
	justify-content: center;
	gap: 25px;

	@media ${media.smaller} {
		gap: 8px;
		text-align: center;
		align-items: center;
		max-width: 100%;
	}
`;

export const Title = styled.span`
	display: flex;
	gap: 1ch;

	@media ${media.smaller} {
		transform: scale(0.8);
	}
`;

export const Card = styled.div`
	min-width: 380px;
	max-width: 700px;
	display: flex;
	flex: 2;
	background-color: ${colors.white};
	box-shadow: ${shadow.larger};
	border-radius: 16px;
	padding: ${({ noCardPadding }) => (noCardPadding ? '0' : '80px 40px')};

	@media ${media.smaller} {
		min-width: unset;
		padding: ${({ noCardPadding }) => (noCardPadding ? '0' : '40px 24px')};
	}
`;
