/* eslint-disable camelcase */
import sizeHelper from 'helpers/size';
import { weight } from 'public/styles';

/**
 * Texts and paragraphs
 */
export const textSize = (size_name) => {
	return sizeHelper('p', size_name, 'text', 'mxl', 'inherit');
};
export const textLineHeight = (size_name) => {
	return sizeHelper('p', size_name, 'lineHeight', null, 'inherit');
};
/* Media queries: */
export const textSizeML = (size_name) => {
	return sizeHelper('p', size_name, 'text', 'ml', 'inherit');
};
export const textSizeMS = (size_name) => {
	return sizeHelper('p', size_name, 'text', 'ms', 'inherit');
};
export const textSizeMXS = (size_name) => {
	return sizeHelper('p', size_name, 'text', 'mxs', 'inherit');
};
/**
 * Font Weights
 */
export const fontWeight = (num) => {
	switch (num) {
	case 'regular':
		return weight.regular;
	case 'bold':
		return weight.bold;
	default:
		return weight.regular;
	}
};
